<template>
  <div>
    <div>
      <information v-if="label == 'information'"></information>
      <collection v-else-if="label == 'collection'"></collection>
      <setting v-else-if="label == 'setting'"></setting>
     
      <answeringQuestion v-else-if="label =='answeringQuestion'"></answeringQuestion>
      <noticeClassContent @back='back' :id="id" v-else-if="label=='noticeClassContent'"></noticeClassContent>
       <noticeClass @editlable="editlable()" @noticeClass='noticeClass' v-show="label =='noticeClass'"></noticeClass>
    </div>
  </div>
</template>
<script>
import information from "./information.vue";
import collection from "./collection.vue";
import setting from "./setting.vue";
import noticeClass from "./noticeClass.vue"; //课程上课列表
import answeringQuestion from "./answeringQuestion.vue";
import noticeClassContent  from './noticeClassContent.vue' //上课详细内容  
export default {
  name: "mainIndex",
  data(){
    return{
      id:''
    }
  },
  props: {
    label: {
      type: String,
      default: "information",
    },
  },
  components: {
    information,
    setting,
    collection,
    noticeClass,
    answeringQuestion,
    noticeClassContent
  },
  methods: {
    editlable(){
      this.$emit('editlable','noticeClassContent')
    },
    noticeClass(id){
      this.id = id
    },
    back(){
      this.$emit('back')
    }
  }
};
</script>