<template>
  <div class="personal-center">
    <div class="content-center">
      <!-- 侧边栏 -->
      <div class="sidebar">
        <div class="h4">个人信息</div>
        <div style="width: 100%">
          <div class="menu" v-for="(item, index) in menuList" :key="index">
            <div class="menu-1" @click="onMenu(item.id)">
              <div class="message-warn">
                <img :src="item.icon" width="22px" height="22px" />
                <div style="margin-left: 10px">{{ item.menuTitle }}</div>
                <div class="warn" v-if="item.menuTitle==='课程信息' && messageId>0"></div>
              </div>
              <i v-if="menuId.includes(item.id)" class="el-icon-arrow-down"></i>
              <i v-else class="el-icon-arrow-up"></i>
            </div>
            <div v-if="menuId.includes(item.id)">
              <div
                v-for="(i, index) in item.menuTitleList"
                :key="index"
                class="menu-2"
                @click="onSubmenu(i.id, i.common,i.title)"
                :style="
                  'background-color:' +
                  (submenuId.includes(i.id) ? '#4394FF' : '#fff') +
                  ';color:' +
                  (submenuId.includes(i.id) ? '#fff' : '#AFAFAF')
                "
              >
                <div class="i-title message-warn">{{ i.title }}
                  <div class="warn-dian" v-if="i.title==='上课通知' && messageId>0"></div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 侧边栏主体 -->
      <div class="content-portion">
        <mainIndex :label="label" @back='back' @editlable='editlable'></mainIndex>
      </div>
    </div>
  </div>
</template>
<script>
import mainIndex from "./personal/mainIndex.vue";
import { getTimetableClassNotice,getTimetableRead } from "@/api/course";
export default {
  components: {
    mainIndex,
  },
  data() {
    return {
      menuList: [
        {
          id: 1,
          icon: require("../../assets/people.png"),
          menuTitle: "个人信息",
          menuTitleList: [
            {
              id: 1,
              title: "基本信息",
              common: "information",
            },
          ],
        },
        {
          id: 2,
          icon: require("../../assets/book.png"),
          menuTitle: "课程信息",
          menuTitleList: [
            // {
            //   id: 2,
            //   title: "收藏题目",
            //   common: "collection",
            // },
            {
              id: 3,
              title: "我的答疑",
              common: "answeringQuestion",
            },
            {
              id: 4,
              title: "上课通知",
              common: "noticeClass",
            },
          ],
        },
        {
          id: 3,
          icon: require("../../assets/clock-2.png"),
          menuTitle: "设置",
          menuTitleList: [
            {
              id: 5,
              title: "账号安全",
              common: "setting",
            },
          ],
        },
      ],
      menuId: [1],
      submenuId: [1],
      label: "information",
      messageId:0
    };
  },
  mounted() {
    this.getTimetable();
  },
  updated(){
    this.getTimetable();
  },
  methods: {

    //获取上课通知提醒
    getTimetable(){
      getTimetableClassNotice().then(res=>{
        if(res.code==200){
          this.messageId=res.data.classNoticeNumber
        }
      })
    },
    onMenu(id) {
      (this.menuId = []), this.menuId.push(id);
      if (id === 1) {
        this.submenuId = [];
        this.submenuId.push(1);
        this.label = "information";
      } else if (id === 2) {
        this.submenuId = [];
        this.submenuId.push(2);
        this.label = "answeringQuestion";
      } else if (id === 3) {
        this.submenuId = [];
        this.submenuId.push(5);
        this.label = "setting";
      }
    },
    onSubmenu(id, common,title) {
      this.submenuId = [];
      this.submenuId.push(id);
      this.label = common;
      if(title=='上课通知' && this.messageId>0){
        getTimetableRead().then(res=>{})
      }
    },
    editlable(a){
      this.label=a
    },
    back(){
      this.label='noticeClass'
    }
  },
};
</script>
<style lang="scss" scoped>
.personal-center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-center {
  width: 70%;
  // background-color: antiquewhite;
  display: flex;
  flex-direction: row;
  .sidebar {
    width: 30%;
    // background-color: royalblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
  }
  .h4 {
    width: 80%;
    text-align: center;
    border-bottom: 1px solid #ebebeb;
    padding: 12px 0;
    font-size: 20px;
    font-weight: bold;
  }
  .content-portion {
    width: 70%;
  }
}
.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-1 {
  cursor: pointer;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 15px 0;
  // background-color: royalblue;
  div {
    width: 50%;
    display: flex;
    flex-direction: row;
  }
}
.menu-1:hover {
  background-color: #cfe1fa;
  opacity: 0.6;
}
.menu-2 {
  border-radius: 0px 16px 16px 0px;
  cursor: pointer;
  .i-title {
    padding: 5px 30px 5px 20px;
    margin: 15px 0;
  }
}
.message-warn{
  position: relative;
  .warn{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -4px;
    background-color: red;
  }
}
.warn-dian{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 20px;
  background-color: red;
}
</style>