<template>
  <div style="width: 100%">
    <div class="h4">账号安全</div>
    <div class="code-flex">
      <img class="code" src="../../../assets/code.png" alt="" />
      <div class="code-clo">
        <div>
          <div style="font-size: 16px">密码</div>
          <div class="p-center">保护账号信息和账号安全</div>
        </div>
        <button class="btn-pas" @click="onChangePassword(passBool)">
          修改密码
        </button>
      </div>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="passBool"
      width="30%"
      :before-close="handleClose"
    >
      <div class="changePassword">
        <input
          class="margin-15"
          ref="originalPassword"
          type="password"
          name=""
          id=""
          placeholder="填写原密码"
        />
        <input
          class="margin-15"
          ref="newPassword"
          type="password"
          name=""
          id=""
          placeholder="填写新密码"
        />
        <input
          class="margin-15"
          ref="password"
          type="password"
          name=""
          id=""
          placeholder="填写确认密码"
        />
        <el-button
          type="primary"
          round
          class="changePassword-btn"
          @click="onNotarize"
          >确认修改</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { updatePassword } from "@/api/course.js";
export default {
  name: "setting",
  data() {
    return {
      passBool: false,
      originalPassword: "",
      newPassword: "",
      password: "",
    };
  },
  methods: {
    handleClose() {
      this.passBool = false;
    },
    onChangePassword(bool) {
      if (!bool) {
        this.passBool = true;
      }
    },
    //提交
    onNotarize() {
      //输入的值
      var original = this.$refs.originalPassword.value;
      var newpass = this.$refs.newPassword.value;
      var password = this.$refs.password.value;
      if (original.trim() == null || original.trim() == "") {
        this.$message({
          message: "原密码不能是空值哦~",
          type: "warning",
        });
        return;
      }
      if (
        newpass.trim() == null ||
        newpass.trim() == "" ||
        password.trim() == null ||
        password.trim() == ""
      ) {
        this.$message({
          message: "密码不能是空值哦~",
          type: "warning",
        });
        return;
      } else {
        if (newpass != password) {
          this.$message({
            message: "输入的两次新密码不一样，请重新修改哦~",
            type: "warning",
          });
          return;
        }
      }

      updatePassword({
        newPassword: newpass,
        newPasswordAgain: password,
        password: original,
      }).then((res) => {
        if (res.code == 200) {
          this.passBool = false; //关闭窗口
          this.$message({
            message: "修改成功",
            type: "success",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.h4 {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 400;
  color: #434343;
  padding-left: 30px;
}
.code {
  width: 40px;
  height: 40px;
  margin-right: 25px;
}
.code-flex {
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.code-clo {
  width: 80%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f0f0f0;
  padding: 0 50px 0 20px;
}
.btn-pas {
  width: 76px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #aeaeae;
  border-radius: 21px;
  font-size: 12px;
  font-weight: 400;
  color: #aeaeae;
  cursor: pointer;
}
.p-center {
  font-size: 14px;
  font-weight: 400;
  color: #aeaeae;
  margin-top: 10px;
}
.pup {
  width: 100%;
  height: 490px;
  background: rgba(97, 96, 96, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.changePassword {
  width: 93%;
  padding: 0 20px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .changePassword-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
  }
  .changePassword-btn {
    align-self: flex-end;
    margin-bottom: 20px;
  }
  .margin-15 {
    margin: 15px 0;
    padding: 10px 0;
    // border-color: #FFFFFF;
    //去除input边框和颜色
    outline-color: invert;
    outline-style: none;
    outline-width: 0px;
    border: none;
    border-style: none;
    text-shadow: none;
    outline-color: transparent;
    box-shadow: none;
    border-bottom: 1px solid #f3f3f3 !important;
  }
}
</style>