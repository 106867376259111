<template>
  <div>
    <div class="h4">上课通知</div>
    <div class="notice">
      <div class="notice-list" v-for="(item, index) in noticeList" @click="routerContent(item.id)" :key="index">
        <div class="notice-list-title">
          <div>课程上课</div>
        </div>
        <p class="margin-left-10">
          时间：{{ item.date }}&nbsp;&nbsp;{{ item.week }}&nbsp;&nbsp;{{
            item.classTime
          }}
        </p>
        <p v-if="item.place != null" class="margin-left-10" style="width: 90%">
          地点：{{ item.place }}
        </p>
        <p class="margin-left-10" style="width: 90%">
          内容：{{ item.content }}
        </p>
        <p
          v-if="item.remarks != null"
          class="margin-left-10"
          style="width: 90%"
        >
          备注：{{ item.remarks }}
        </p>
        <div v-if="item.file != null" class="margin-left-10 classdownload">
          课件下载：<a ref="download" :href="item.file" @click.stop :download="item.file">点击下载</a>
        </div>
      </div>
      <el-pagination
        v-if="total / limit > 1"
        style="margin-top: 20px"
        @current-change="currentChange"
        background
        :hide-on-single-page="true"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getNoticeInfo } from "@/api/course.js";
export default {
  name: "noticeClass",
  data() {
    return {
      noticeList: [],
      total: 0,
      limit: 10,
      page: 1,
    };
  },
  created() {
    this.onGetNoticeInfo();
  },
  methods: {
    //列表
    onGetNoticeInfo() {
      var param = {
        limit: this.limit,
        page: this.page,
      };
      getNoticeInfo(param).then((res) => {
        if (res.code == 200) {
          this.noticeList = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //分页
    currentChange(event) {
      // this.pageNum = event;
      this.page=event
     this.onGetNoticeInfo()
    },
    routerContent(id){

      this.$emit('noticeClass',id)
     this.$emit('editlable','noticeClassContent')
     
    },
  },
};
</script>
<style lang="scss" scoped>
.h4 {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 400;
  color: #434343;
  padding-left: 30px;
}
.notice {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.notice-list {
  width: 100%;
  background-color: #f9f9f9;
  margin-top: 30px;
  cursor: pointer;
  .notice-list-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    div {
      padding: 15px;
    }
  }
  .margin-left-10 {
    margin-left: 15px;
  }
  .classdownload {
    margin-bottom: 15px;
  }
}
</style>