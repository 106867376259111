<template>
  <div>
    <div class="h4">题目课程</div>
    <div v-if="isList">
      <div class="course" v-if="subjects.length > 0">
        <div
          class="course-list"
          v-for="(item, index) in subjects"
          :key="index"
          @click="viewDetail(item)"
        >
          <div class="course-list-content" v-html="item.title"></div>
          <div class="divider"><el-divider></el-divider></div>
        </div>
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="limit"
            :total="total"
            @current-change="currentChange"
            :hide-on-single-page="true"
          >
          </el-pagination>
        </div>
      </div>
      <div v-else>
        <el-empty description="暂无数据"></el-empty>
      </div>
    </div>
    <div v-else>
      <div class="body-content">
        <div class="body-content-img">
          <img
            src="../../../assets/back-left.png"
            alt=""
            width="30px"
            height="30px"
            @click="back"
          />
        </div>
        <div class="practicing">
          <div v-if="subjectInfo.type === 1" class="practicing-type">
            单选题
          </div>
          <div v-if="subjectInfo.type === 2" class="practicing-type">
            多选题
          </div>
          <div v-else-if="subjectInfo.type === 3" class="practicing-type">
            简答题
          </div>
          <div class="practicing-content">
            <div class="practicing-title">
              <span v-html="subjectInfo.title"></span>
            </div>
            <!-- 单选题、多选题 -->
            <div v-if="subjectInfo.type == 1 || subjectInfo.type == 2">
              <div
                class="practicing-option"
                v-for="(item, index) in subjectInfo.topicOptions"
                :key="index"
                :class="[item.isClick ? 'select' : 'no-select']"
              >
                <span v-if="!item.isClick" style="margin-right: 10px">{{
                  item.opt
                }}</span>
                <img
                  v-else-if="item.isClick"
                  src="../../../assets/true.png"
                  width="24px"
                  height="24px"
                />
                <span v-html="item.value"></span>
              </div>
            </div>
            <div v-else>
              <el-input
                type="textarea"
                :rows="5"
                placeholder="请输入内容"
                v-model="textareaValue"
              >
              </el-input>
            </div>
            <div class="practicing-answer">
              <div class="practicing-answer-text">正确答案</div>
              <div
                class="practicing-answer-option"
                v-html="subjectInfo.answer"
              ></div>
            </div>
            <div class="practicing-analysis">
              <span>解析：</span>
              <span v-html="subjectInfo.analysis"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { topicCollectListPage, topicCollectInfo } from "@/api/course";
export default {
  name: "collection",
  data() {
    return {
      subjects: [],
      total: 0,
      page: 1,
      limit: 20,
      isList: true,
      subjectId: {
        appTestVo: {
          name: "",
        },
      },
      subjectInfo: {
        testResultVo: {
          answer: "",
        },
      }, // 一道题目的信息
      textareaValue: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    back() {
      this.isList = true;
    },
    viewDetail(item) {
      this.isList = false;
      topicCollectInfo({ id: item.id }).then((res) => {
        this.subjectInfo = res.data;
      });
    },
    getList() {
      topicCollectListPage({ page: this.page, limit: this.limit }).then(
        (res) => {
          var list = res.data.list;
          this.total = res.data.total;
          this.subjects = list;
        }
      );
    },
    currentChange(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.body-content-img img {
  cursor: pointer;
}
.h4 {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 400;
  color: #434343;
  padding-left: 30px;
  margin-bottom: 30px;
}
.course {
  padding: 15px;
  margin-top: 30px;
}
.body-content {
  margin-left: 30px;
  .body-content-img {
    margin-bottom: 20px;
  }
  .row-name {
    margin-top: 30px;
    font-size: 20px;
  }
  .practicing {
    margin-top: 6px;
    display: -webkit-inline-box;
    font-weight: 400;
    margin-bottom: 120px;
    .practicing-type {
      width: 80px;
      font-size: 20px;
      color: #4394ff;
    }
    .practicing-content {
      width: 655px;
      font-size: 20px;
      color: #000000;
      margin-right: 80px;
      .practicing-option {
        margin-top: 20px;
        display: flex;
      }
      .view-analysis {
        font-size: 18px;
        font-weight: 400;
        color: #4394ff;
        margin-top: 50px;
        display: flex;
        align-items: center;
      }
      .practicing-answer {
        margin-top: 40px;
        font-weight: 400;
        display: flex;
        .practicing-answer-text {
          font-size: 18px;
          color: #000000;
          line-height: 28px;
          margin-right: 20px;
        }
        .practicing-answer-option {
          font-size: 26px;
          color: #4394ff;
        }
      }
      .practicing-analysis {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        color: #000000;
        margin-top: 15px;
      }
    }
    .practicing-time {
      width: 315px;
      height: 368px;
      .practicing-time-clock {
        width: 100%;
        height: 86px;
        background: #ffffff;
        border: 1px solid #c9c9c9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        .practicing-time-clock-text {
          font-size: 30px;
          font-weight: 400;
          color: #4394ff;
          margin-left: 3px;
        }
      }
      .practicing-time-option {
        margin-top: 10px;
        height: 368px;
        background: #ffffff;
        border: 1px solid #c9c9c9;
        border-radius: 5px;
        padding: 15px 20px 0 20px;
        position: relative;
        .option-number {
          font-size: 20px;
          font-weight: 400;
          color: #000000;
          margin-bottom: 15px;
        }
        .option-line {
          height: 1px;
          background-color: #dcdfe6;
        }
        .option-bottom {
          position: absolute;
          bottom: 0;
          width: 265px;
          .option-bottom-page {
            text-align: center;
            margin-top: 30px;
          }
          .option-bottom-submit {
            margin: 20px auto;
            font-size: 18px;
            font-weight: 400;
            color: #4394ff;
            text-align: center;
          }
        }
      }
    }
  }
}
.body-head {
  height: 22px;
  margin: 0 30px;
}
.course-list {
  height: auto;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  cursor: pointer;
  .course-list-content {
    padding: 0 30px;
  }
  .divider {
    padding: 0 10px;
  }
}
.pagination {
  text-align: center;
}
</style>