<template>
  <div>
    <div class="h4">答疑</div>
    <div class="body-content" v-if="isList">
      <div v-if="subjects.length > 0">
        <div
          class="body-content-list"
          v-for="(item, index) in subjects"
          :key="index"
          @click="viewDetail(item)"
        >
          <div class="body-content-list-head">
            <span
              v-if="userId == item.creator"
              class="body-content-list-head-text"
            >
              发布到：
            </span>
            <span v-else class="body-content-list-head-text"> 回复到： </span>
            <span class="body-content-list-head-title">
              {{ item.courseName }}
            </span>
          </div>
          <div class="divider"></div>
          <div class="body-content-list-content" v-html="item.content"></div>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="limit"
          :total="total"
          @current-change="currentChange"
          :hide-on-single-page="true"
          class="pagination"
        >
        </el-pagination>
      </div>
      <div v-else>
        <el-empty description="暂无数据"></el-empty>
      </div>
    </div>
    <div v-else>
      <div>
        <div class="body-content-img">
          <img
            src="../../../assets/back-left.png"
            alt=""
            width="30px"
            height="30px"
            @click="back"
          />
        </div>
        <div class="answer-module" v-if="questionDetailList != null">
          <div class="answer-central">
            <div class="answer-central-section" v-if="questionDetailList">
              <img
                class="flex-width-head"
                :src="questionDetailList.avatar"
                alt=""
              />
              <div class="answer-central-section-content">
                <div class="content-name">
                  {{ questionDetailList.creatorName }}
                </div>
                <div class="content-time">
                  {{ questionDetailList.createTime }}
                </div>
                <p class="content-p" v-html="questionDetailList.content"></p>
              </div>
            </div>
            <div class="reply-title">
              <div>{{ replyTotal }}回复</div>
              <div class="reply-title-comment" @click="comment">
                <div class="reply-title-comment-text">评论</div>
                <img
                  src="../../../assets/comment.png"
                  class="flex-width-huifu"
                  alt=""
                />
              </div>
            </div>
            <span
              v-if="questionReplyList.length > 0"
              class="answer-central-detail"
            >
              <div
                class="answer-central-section"
                v-for="(item, index) in questionReplyList"
                :key="index"
              >
                <img class="flex-width-head" :src="item.avatar" alt="" />
                <div class="answer-central-section-content">
                  <div class="content-name">{{ item.creatorName }}</div>
                  <div class="content-time">{{ item.createTime }}</div>
                  <p class="content-p" v-html="item.content"></p>
                </div>
              </div>
              <el-pagination
                class="pagination"
                @current-change="currentChange2"
                background
                layout="prev, pager, next"
                :total="replyTotal"
                :page-size="limit"
              ></el-pagination>
            </span>
            <div v-else>
              <el-empty description="暂无回复"></el-empty>
            </div>
          </div>
          <el-dialog :visible.sync="addAnswerDialogVisible" width="850" center>
            <div class="dialog">
              <quill-editor
                v-model="content"
                ref="myQuillEditor"
                :options="editorOption"
                @blur="onEditorBlur($event)"
                @focus="onEditorFocus($event)"
                @change="onEditorChange($event)"
                class="quill"
              >
              </quill-editor>
            </div>
            <div class="button">
              <el-button size="mini" type="primary" @click="add" class="submit"
                >提交</el-button
              >
            </div>
          </el-dialog>
        </div>
        <div v-else>
          <el-empty description="数据不存在"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getUserQuestionList,
  getQuestionInfo,
  getQuestionReplyList,
  addQuestionReply,
} from "@/api/course";
import { quillEditor, Quill } from "vue-quill-editor";
import { container, ImageExtend, QuillWatch } from "quill-image-extend-module";
Quill.register("modules/ImageExtend", ImageExtend);
export default {
  name: "answeringQuestion",
  data() {
    return {
      subjects: [],
      total: 0,
      replyTotal: 0,
      replyPage: 1,
      page: 1,
      limit: 10,
      selectId: 0,
      isList: true,
      questionReplyList: [],
      questionDetailList: [],
      addAnswerDialogVisible: false,
      subjectId: {
        appTestVo: {
          name: "",
        },
      },
      subjectInfo: {
        testResultVo: {
          answer: "",
        },
      }, // 一道题目的信息
      textareaValue: "",
      content: ``,
      editorOption: {
        modules: {
          ImageExtend: {
            // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            name: "file", // 图片参数名
            size: 3, // 可选参数 图片大小，单位为M，1M = 1024kb
            action:
              "https://www.zhijiao168.com/cloudClassroom/app/api/app/system/upload/file", // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return res.data;
            },
            headers: (xhr) => {
              xhr.setRequestHeader(
                "authentication",
                localStorage.getItem("web-token")
              );
            }, // 可选参数 设置请求头部
            sizeError: () => {}, // 图片超过大小的回调
            start: () => {}, // 可选参数 自定义开始上传触发事件
            end: () => {}, // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {}, // 可选参数 上传失败触发的事件
            success: () => {}, // 可选参数  上传成功触发的事件
            change: (xhr, formData) => {}, // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // 如果不上传图片到服务器，此处不必配置
            container: [["image"]], // container, // container为工具栏，此次引入了全部工具栏，也可自行配置
            handlers: {
              image: function () {
                // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id);
              },
            },
          },
        },
        placeholder: "请输入评论的内容",
      },
    };
  },
  mounted() {
    this.userId = localStorage.getItem("userId");
    this.getList();
  },
  methods: {
    add() {
      if (this.content == "") {
        this.$message({ message: "请输入内容", type: "error" });
        return;
      }
      addQuestionReply({
        questionId: this.selectId,
        content: this.content,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "回复成功，请等待审核通过！",
            type: "success",
          });
          this.addAnswerDialogVisible = false;
        }
      });
    },
    viewDetail(item) {
      this.isList = false;
      this.selectId = item.id;
      this.getQuestionInfo(this.selectId);
    },
    getQuestionInfo(id) {
      getQuestionInfo({ id }).then((res) => {
        if (res != undefined) {
          this.questionDetailList = res.data;
          this.getQuestionReply(id);
        } else {
          this.questionDetailList = null;
        }
      });
    },
    //答疑回复
    getQuestionReply(id) {
      getQuestionReplyList({
        questionId: id,
        limit: this.limit,
        page: this.replyPage,
      }).then((res) => {
        if (res.code == 200) {
          this.questionReplyList = res.data.list;
          this.replyTotal = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    comment() {
      this.addAnswerDialogVisible = true;
    },
    getList() {
      getUserQuestionList({ page: this.page, limit: this.limit }).then(
        (res) => {
          this.total = res.data.total;
          if (res.code == 200) {
            this.subjects = res.data.list;
          }
        }
      );
    },
    back() {
      this.isList = true;
    },
    currentChange(page) {
      this.page = page;
      this.getList();
    },
    currentChange2(page) {
      this.replyPage = page;
      this.getQuestionReply(this.selectId);
    },
    onEditorReady(editor) {
      // 富文本编辑框准备编辑器
    },
    onEditorBlur() {}, // 富文本编辑框失去焦点事件
    onEditorFocus() {}, // 富文本编辑框获得焦点事件
    onEditorChange() {}, // 富文本编辑框内容改变事件
  },
};
</script>

<style lang="scss" scoped>
.body-content-img img {
  margin-left: 25px;
  cursor: pointer;
}
.h4 {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 400;
  color: #434343;
  padding-left: 30px;
}
.body-content {
  padding: 15px;
  margin-top: 30px;
  .body-content-img {
    margin-bottom: 20px;
  }
  .body-content-list {
    width: 100%;
    height: auto;
    background: #f9f9f9;
    cursor: pointer;
    margin-bottom: 20px;
    .body-content-list-head {
      padding: 10px 0 10px 15px;
      .body-content-list-head-text {
        color: #9a9a9a;
      }
      .body-content-list-head-title {
        color: #000000;
      }
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: #ebebeb;
    }
    .body-content-list-content {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      padding: 15px 15px 20px 15px;
      width: 100%;
      ::v-deep{
        img{
          width: 100%;
        }
      }
    }
  }
}
.body-head {
  height: 22px;
  margin: 0 30px;
}
.course-list {
  height: auto;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  .course-list-content {
    padding: 0 50px;
  }
  .divider {
    padding: 0 30px;
  }
}
.pagination {
  margin: 20px 0;
  text-align: center;
}
.answer-module {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  // background-color: #F4F5F8;
}
.answer-central {
  max-width: 1200px;
  width: 92%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  margin-top: 30px;
}
.answer-central-detail {
  width: 88%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.answer-central-section {
  width: 90%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f3f3f3;
  margin-top: 30px;
  .flex-width-head {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .flex-width-content {
    width: 201px;
    height: 142px;
    opacity: 1;
    border-radius: 5px;
  }
}
.answer-central-section-content {
  width: 90%;
  margin-left: 10px;
  margin-bottom: 30px;
  .content-name {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
  }
  .content-time {
    font-size: 8px;
    font-weight: 400;
    color: #9e9e9e;
  }
  .content-p {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
}
.flex-width-huifu {
  width: 24px;
  height: 24px;
  margin-left: 5px;
}
.reply-title {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  .reply-title-comment {
    font-size: 18px;
    font-weight: bold;
    display: -webkit-inline-box;
    cursor: pointer;
    .reply-title-comment-text {
      color: #4394ff;
    }
  }
}
.quill {
  width: 100%;
  height: 400px;
}
.button {
  width: 100%;
  text-align: right;
  .submit {
    margin-top: 80px;
  }
}
</style>