<template>
  <div>
    <div class="h4">课程详情</div>
    <div class="back">
          <img
            src="../../../assets/back-left.png"
            alt=""
            style="width: 30px; height: 30px"
            @click="goBack()"
          />
        </div>
    <div class="notice" style="margin:14px">
      <el-card style="width:100%">
        
      <div class="card-text">
        <div>时间：</div>
        <div>{{ notices.date }} {{ notices.week }} {{ notices.classTime }}</div>
      </div>
      <div class="card-text">
        <div>地点：</div>
        <div>{{ notices.place }}</div>
      </div>
      <div class="card-text">
        <div class="card-text-content">内容：</div>
        <div class="card-text-itemContent">{{ notices.content }}</div>
      </div>
      <div v-if="notices.file != null && notices.file != ''" class="card-text">
        <span>课件下载：</span>
        <a
          :href="notices.file"
          target="_blank"
          title=""
          :download="notices.place"
          class="download-text"
          >点击下载</a
        >
      </div>
      <div class="card-text">
        <div class="card-text-content">备注：</div>
        <div class="card-text-itemContent rich-text" v-html="notices.remarks"></div>
      </div>
      </el-card>
    </div>
     
    </div>
</template>
<script>
import  {gettimetable} from '@/api/course'
  export default {
    data(){
      return {
         notices: {},
      }
    },
    props:{
      id:{
        require:true,
        type:[Number,String]
      }
    },
    created(){
      this.getNoticeInfo(this.id)
    },
    methods: {
     getNoticeInfo(id) {
      gettimetable({ id: parseInt(id) }).then((res) => {
        this.notices = res.data;
      });
    },
    goBack(){
      this.$emit('back')
    }
    }
  }
</script>
<style lang="scss" scoped>
.h4 {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 400;
  color: #434343;
  padding-left: 30px;
}
.card-title {
    display: flex;
    line-height: 30px;
    font-weight: bold;
    color: #000000;
    font-size: 26px;
  }
  .card-text {
    width: 100%;
    height: auto;
    text-align: left;
    display: flex;
    line-height: 30px;
    font-size: 18px;
    font-weight: 400;
    color: black;
    .card-text-content {
      width: 80px;
    }
    .card-text-itemContent {
      width: 100%;
    }
  }
  .back{
    height: 25px;
    margin-top: 5px;
    padding-left: 10px;
  }
</style>