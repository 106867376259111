<template>
  <div class="body">
    <div class="body-head">
      <span>基本资料</span>
      <span class="body-head-edit" @click="editUserInfo"
        ><img
          src="../../../assets/edit_2.png"
          alt="编辑"
          width="22px"
          height="22px"
        /><span class="body-head-edit-text">编辑</span></span
      >
    </div>
    <div class="body-content">
      <div class="body-content-avatar">
        <img
          v-if="userInfo.avatar != undefined && userInfo.avatar != ''"
          :src="userInfo.avatar"
          height="150px"
          width="150px"
        />
        <img
          v-else
          src="../../../assets/avatar.png"
          height="150px"
          width="150px"
        />
        <el-upload
          class="upload-demo body-content-avatar-text"
          :action="actionURL"
          :multiple="false"
          :headers="headers"
          :limit="3"
          :on-success="handleSuccess"
          :on-error="handleError"
          :show-file-list="false"
          v-show="edit"
          ><div>更换头像</div>
        </el-upload>
      </div>
      <div class="body-content-text-username body-content-margin">用户名</div>
      <el-input
        v-model="userInfo.name"
        placeholder="暂无用户名"
        :disabled="true"
        class="body-content-margin"
      ></el-input>
      <div class="body-content-text-username body-content-margin">注册时间</div>
      <el-input
        v-model="userInfo.createTime"
        placeholder="注册时间"
        :disabled="true"
        class="body-content-margin"
      ></el-input>
      <el-form ref="form" :model="userInfo" label-width="80px">
        <div class="body-content-text-username body-content-margin">电话</div>
        <el-input
          v-model="userInfo.phone"
          placeholder="暂无电话"
          :disabled="!edit"
          class="body-content-margin"
        ></el-input>
        <div class="body-content-text-username body-content-margin">昵称</div>
        <el-input
          v-model="userInfo.username"
          placeholder="暂无昵称"
          :disabled="!edit"
          class="body-content-margin"
        ></el-input>
        <div class="body-content-text-username">性别</div>
        <el-input
          v-model="userInfo.sex"
          placeholder="暂无性别"
          :disabled="true"
          class="body-content-margin body-content-text-sex-top"
          v-if="!edit"
        ></el-input>
        <el-radio-group
          v-model="sex"
          v-else
          class="body-content-text-sex-radio body-content-text-sex-top"
        >
          <el-radio label="1">男</el-radio>
          <el-radio label="2">女</el-radio>
        </el-radio-group>
        <div class="body-content-text-username body-content-margin">地址</div>
        <el-input
          v-model="userInfo.address"
          placeholder="暂无地址"
          :disabled="!edit"
          class="body-content-margin"
        ></el-input>
        <div class="submit" v-show="edit">
          <el-button type="primary" round class="submit-button" @click="save"
            >保存</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getCurrentUser, updateUserInfo } from "@/api/course";
import baseURL from "@/utils/api_host.js"
export default {
  name: "information",
  data() {
    return {
      edit: false,
      sex: "未知",
      headers: {
        authentication: "",
      },
      newImage: "",
      actionURL: "",
      fileList: [],
      userInfo: {
        name: "",
        username: "",
        phone: "",
        createTime: "",
        address: "",
        sex: "0",
        avatar: "",
      },
    };
  },
  mounted() {
    this.actionURL = baseURL.baseURL + "/system/upload/file"
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      const { data } = await getCurrentUser();
      this.edit = false;
      this.userInfo = {
        avatar: data.avatar,
        name: data.name,
        username: data.nickName,
        phone: data.phone,
        createTime: data.createTime,
        address: data.address,
        sex: data.sex == 0 ? "未知" : data.sex == 1 ? "男" : "女",
      };
      this.headers.authentication = localStorage.getItem("web-token");
      this.sex =
        this.userInfo.sex == "未知"
          ? "0"
          : this.userInfo.sex == "男"
          ? "1"
          : "2";
    },
    handleSuccess(response, file, fileList) {
      if (response.code === 200) {
        this.userInfo.avatar = file.response.data;
        this.$message.success("上传成功");
      } else this.handleError();
    },
    handleError(err, file, fileList) {
      this.$message.error("上传失败");
    },
    save() {
      this.userInfo.sex = this.radio;
      updateUserInfo({
        address: this.userInfo.address,
        nickName: this.userInfo.username,
        phone: this.userInfo.phone,
        sex: this.sex,
        avatar: this.userInfo.avatar,
      }).then((res) => {
        if (res != undefined) {
          this.$message.success("修改成功");
          setTimeout(() => {
            this.getUserInfo();
          }, 1500);
        }
      });
    },
    editUserInfo() {
      this.edit = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  width: 93%;
  .body-head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    padding: 12px 0;
    font-size: 16px;
    font-weight: 400;
    color: #434343;
    padding-left: 30px;
    margin-bottom: 30px;
    .body-head-edit {
      display: flex;
      cursor: pointer;
      .body-head-edit-text {
        margin-top: 3px;
        color: #4394ff;
      }
    }
  }
  .body-content {
    margin: 0 30px;
    .body-content-avatar {
      width: 150px;
      height: 150px;
      margin: 50px auto;
      cursor: pointer;
      border-radius: 50px;
      .body-content-avatar-text {
        margin-top: 10px;
        font-size: 14px;
        color: #4394ff;
        text-align: center;
      }
    }
    .body-content-text-username {
      font-size: 16px;
      font-weight: 400;
      color: #aaaaaa;
    }
    .body-content-text-sex-radio {
      height: 40px;
    }
    .body-content-text-sex-top {
      margin-top: 20px;
    }
    .body-content-margin {
      margin-bottom: 20px;
      .el-input__inner {
        background-color: white;
      }
      .input {
        background-color: white;
      }
    }
    .submit {
      width: 385px;
      margin: 70px auto 20px auto;
      .submit-button {
        width: 100%;
      }
    }
  }
}
</style>